<template>
  <div>
    <div
      id="kt_subheader"
      class="subheader py-2 py-lg-4 mb-5 gutter-b subheader-solid"
    >
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      >
        <!--begin::Info-->
        <div class="d-flex align-items-center flex-wrap mr-2">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
            Export Manager
          </h5>
          <!--end::Page Title-->
          <!--begin::Action-->
          <div
            class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
          ></div>
          <span class="text-muted font-weight-bold mr-4"> </span>
          <div
            class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
          ></div>
          <!--end::Action-->
        </div>
        <div class="d-flex align-items-center">
         
        </div>
        <!--end::Info-->
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <v-card height="100"  color="#386498">
            <v-row align="center" justify="center">
              <v-col class="text-center" cols="12">
                <h4 class="display-3 text-white  mb-4">Export Items</h4>
              </v-col>
            </v-row>
          </v-card>
        </div>
        <div class="col-lg-6">
          <v-card class="h-100">
            <v-card-title align="left">
              <p class="text-wrap">
                You can export to your needed outside use.
              </p>
            </v-card-title>
          </v-card>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <v-container fluid>
            <v-row dense>
              <v-col v-for="card in cards" :key="card.title" :cols="card.flex">
                <v-card
                  color="#385F73"
                  :img="card.src"
                  height="160px"
                  :href="card.detail"
                >
                  <v-card-title
                    class="headline h2 text-white"
                    v-text="card.title"
                  ></v-card-title>
                  <v-card-subtitle
                    class="text-white "
                    v-text="card.short_description"
                  ></v-card-subtitle>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <div class="text-center">
                      <v-btn class="ma-2 mr-2" tile color="indigo" light
                        >View</v-btn
                      >
                      <v-btn :href="card.url" class="ma-2" tile light="success">
                        <v-icon left>mdi-pencil</v-icon> Edit
                      </v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
        
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    cards: [
      {
        title: "Goose Marketplace",
        src: "/media/header-green.jpg",
        color: "#111111",
        url: "/pim/channels/edit",
        detail: "/pim/channels/detail",
        short_description: "The B2B Marketplace for your Customers",
        delete: "false",
        flex: 6,
      },
      {
        title: "Export to CSV",
        src: "/media/header2.jpg",
        color: "#111111",
        url: "/pim/channels/edit",
        short_description: "Our Website on Shopify or B2B",
        detail: "/pim/channels/detail",
        flex: 6,
      },

      {
        title: "Shopify",
        src: "/media/header-black.jpg",
        short_description: "Push to Shopify API",

        url: "/pim/channels/edit",
        detail: "/pim/channels/detail",
        flex: 6,
      },
    ],

    open: ["public"],
    

  }),
};
</script>
